h1 {
    color: #383A3C;
    font-family: 'General Sans';
    font-size: 80px;
    font-weight: 500;
    margin: 0;
}

h2 {
    color: #383A3C;
    font-family: 'General Sans';
    font-size: 24px;
    font-weight: 500;
}

h3 {
    color: #383A3C;
    font-family: 'General Sans';
    font-size: 60px;
    font-weight: 500;
}


p {
    color: #383A3C;
    font-family: 'General Sans';
    font-size: 24px;
    font-weight: 400;
}